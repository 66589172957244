import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function Leaderboard({ scores }) {
  const [highlightedRows, setHighlightedRows] = useState([]);

  useEffect(() => {
    const newHighlightedRows = [];
    scores.forEach((score, index) => {
      if (index < highlightedRows.length && highlightedRows[index] !== score.walletId) {
        newHighlightedRows.push(index);
      }
    });
    setHighlightedRows(newHighlightedRows);

    const timeout = setTimeout(() => {
      setHighlightedRows([]);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [scores]);

  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: 500,
        margin: '20px auto',
        backgroundColor: '#1c2733',
        border: '4px solid #ffdf00',
        borderRadius: '8px',
        boxShadow: '6px 6px 0px #000000',
        fontFamily: 'Press Start 2P, cursive',
        padding: '10px',
      }}
    >
      <Table aria-label="leaderboard table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#ff9f43', borderBottom: '2px solid #ffdf00' }}>
            <TableCell sx={{ color: '#1c2733', fontWeight: 'bold', fontSize: '1rem', textAlign: 'center', textTransform: 'uppercase' }}>
              Rank
            </TableCell>
            <TableCell align="center" sx={{ color: '#1c2733', fontWeight: 'bold', fontSize: '1rem', textTransform: 'uppercase' }}>
              Wallet ID
            </TableCell>
            <TableCell align="center" sx={{ color: '#1c2733', fontWeight: 'bold', fontSize: '1rem', textTransform: 'uppercase' }}>
              Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scores.slice(0, 10).map((score, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? '#2a3b47' : '#34495e',
                '&:hover': {
                  backgroundColor: '#3b5060',
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: 'bold',
                  color: '#ffdf00',
                  fontSize: '1rem',
                  textAlign: 'center',
                }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: 'IBM Plex Mono, monospace',
                  color: '#ffffff',
                  fontSize: '0.9rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '140px',
                }}
              >
                {score.walletId
                  ? `${score.walletId.substring(0, 6)}...${score.walletId.substring(score.walletId.length - 4)}`
                  : 'Unknown ID'}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  color: '#ffffff',
                  fontSize: '0.9rem',
                }}
              >
                {score.best}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Leaderboard;
