// walletinput.jsx
import React, { useState } from 'react';

const WalletInput = ({ manualWalletId, setManualWalletId, buttonText, updateButtonText }) => {
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setManualWalletId(text);
      updateButtonText('Connect'); // Show "Connect" after pasting
      validateWalletId(text);
    } catch (error) {
      console.error('Error reading clipboard contents:', error);
    }
  };

  const validateWalletId = (walletId) => {
    const isValid = /^[A-HJ-NP-Za-km-z1-9]{32,44}$/.test(walletId);
    if (isValid) {
      setError('');
      setIsConnected(true);
      updateButtonText('Connected'); // Show "Connected" if valid
    } else {
      setError('Invalid wallet ID. Please re-enter.');
      setIsConnected(false);
      updateButtonText('Paste'); // Revert to "Paste" if invalid
    }
  };

  return (
    <div className="wallet-input-container">
      <input
        type="text"
        value={manualWalletId}
        readOnly
        placeholder="Wallet ID"
        className={`wallet-input ${error ? 'error' : ''}`}
      />
      <button
        onClick={handlePasteClick}
        className={`paste-button ${isConnected ? 'connected' : ''}`}
      >
        {buttonText}
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default WalletInput;
