import React, { useEffect, useState } from 'react';
import Game from './components/Game.jsx';
import SideInfo from './components/SideInfo.jsx';
import './style.scss';
import RightInfo from './components/RightInfo.jsx';
import Leaderboard from './components/Leaderboard.jsx';
import WalletInput from './components/WalletInput.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { clusterApiUrl } from '@solana/web3.js';

const network = clusterApiUrl('devnet');

function App() {
  const [score, setScore] = useState(0);
  const [localBest, setLocalBest] = useState(0);
  const [serverBest, setServerBest] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [manualWalletId, setManualWalletId] = useState('');
  const [isWalletValid, setIsWalletValid] = useState(false);
  const [buttonText, setButtonText] = useState('Paste');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchLeaderboard();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchLeaderboard = async () => {
    try {
      const response = await fetch('https://sloppybird.xyz/leaderboard');
      const data = await response.json();
      setLeaderboard(data);
    } catch (error) {
      console.error('Error loading leaderboard:', error);
    }
  };

  const updateButtonText = (text) => setButtonText(text);

  if (isMobile) {
    return <MobileViewWarning />;
  }

  const WalletConnectedApp = () => {
    const walletId = manualWalletId;

    useEffect(() => {
      const isValid = /^[A-HJ-NP-Za-km-z1-9]{32,44}$/.test(walletId);
      setIsWalletValid(isValid);

      if (isValid) {
        loadBestScore(walletId);
      }
    }, [walletId]);

    const loadBestScore = async (walletId) => {
      try {
        const response = await fetch(`https://sloppybird.xyz/get?walletId=${encodeURIComponent(walletId)}`);
        const data = await response.json();
        if (data?.best !== undefined) setServerBest(data.best);
      } catch (error) {
        console.error('Error loading best score:', error);
      }
    };

    const restartGame = () => {
      if (!isWalletValid) {
        toast.error("Connect wallet first", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return; // Prevent game start if wallet is invalid
      }

      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      const game = Game(canvas, ctx, 2, updateScore);
      game.start();
    };

    const updateScore = (newScore) => {
      setScore(newScore);
      if (newScore > Math.max(localBest, serverBest)) {
        setLocalBest(newScore);
        if (walletId) sendScoreToServer(newScore, walletId);
      }
    };

    const sendScoreToServer = async (score, walletId) => {
      try {
        const response = await fetch('https://sloppybird.xyz/save', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ best: score, walletId }),
        });
        const data = await response.json();
        console.log('Score saved:', data);
      } catch (error) {
        console.error('Error saving score:', error);
      }
    };

    return (
      <div className="main">
        <h1 className='title'>Sloppy Bird</h1>
        <div className="wallet-section">
          <WalletInput
            manualWalletId={manualWalletId}
            setManualWalletId={setManualWalletId}
            buttonText={buttonText}
            updateButtonText={updateButtonText}
          />
        </div>
        <div className='container'>
          <div className="score-panel">
            <div className="label">Your Score:</div>
            <div className="score">{score}</div>
            <div className="label">Your Best:</div>
            <div className="best">{Math.max(localBest, serverBest)}</div>
            <button
              className="start-game-button"
              onClick={restartGame}
              disabled={!isWalletValid} // Disable button if wallet ID is invalid
              title={!isWalletValid ? 'Please enter a valid wallet ID' : ''}
            >
              Start Game
            </button>
          </div>
          <div className="game">
            <canvas id="canvas" width={390} height={525} />
          </div>
          <Leaderboard scores={leaderboard} />
        </div>
        <ToastContainer />
      </div>
    );
  };

  return <WalletConnectedApp />;
}

function MobileViewWarning() {
  return (
    <div className="mobile-warning">
      <h1 className="title">Coming Soon to Mobile</h1>
      <p className="description">This game is currently only available on desktop. Please visit us on a larger screen for the full experience.</p>
    </div>
  );
}

export default App;
