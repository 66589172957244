// SloppyBirdLandingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal, Box } from '@mui/material';
import './style.css';
import Leaderboard from './components/Leaderboard.jsx';

const SloppyBirdLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [leaderboardVisible, setLeaderboardVisible] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [referralLink, setReferralLink] = useState('');
  const [inviteCount, setInviteCount] = useState(0);
  const [walletId, setWalletId] = useState('');
  const [showReferralModal, setShowReferralModal] = useState(false); // Modal state
  const [referredWalletId, setReferredWalletId] = useState('');

  // State for toast notification
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Function to show toast
  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
  };

  const isValidWalletId = (walletId) => {
    const walletIdPattern = /^[A-HJ-NP-Za-km-z1-9]{32,44}$/;
    return walletIdPattern.test(walletId);
  };

  useEffect(() => {
    if (leaderboardVisible) fetchLeaderboardData();

    const params = new URLSearchParams(location.search);
    const referrerWalletId = params.get('ref');
    if (referrerWalletId) {
      setShowReferralModal(true); // Show referral modal when referred
    }
  }, [leaderboardVisible, location]);

  const fetchLeaderboardData = () => {
    fetch('https://www.sloppybird.xyz/leaderboard')
      .then(response => response.json())
      .then(data => setLeaderboardData(data))
      .catch(error => console.error("Failed to fetch leaderboard data:", error));
  };

  const generateReferralLink = () => {
    if (isValidWalletId(walletId)) {
      setReferralLink(`${window.location.origin}/?ref=${walletId}`);
      fetchInviteCount(walletId);
    } else {
      showToastMessage("Please enter a valid wallet ID.");
    }
  };

  const fetchInviteCount = (walletId) => {
    fetch(`https://www.sloppybird.xyz/invites?walletId=${walletId}`)
      .then(response => response.json())
      .then(data => setInviteCount(data.inviteCount))
      .catch(error => console.error("Failed to fetch invite count:", error));
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    showToastMessage("Referral link copied to clipboard!");
  };

  const recordReferral = (referrerWalletId, referredWalletId) => {
    fetch('https://www.sloppybird.xyz/referral', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ referrerWalletId, referredWalletId })
    })
    .then(response => {
      if (response.ok) {
        setShowReferralModal(false);
        showToastMessage("Thank you! Your referral has been recorded.");
      } else {
        return response.json().then(data => showToastMessage(data.error));
      }
    })
    .catch(error => console.error("Failed to record referral:", error));
  };

  const handleReferralSubmit = () => {
    const params = new URLSearchParams(location.search);
    const referrerWalletId = params.get('ref');

    if (isValidWalletId(referredWalletId) && referrerWalletId) {
      recordReferral(referrerWalletId, referredWalletId);
    } else {
      showToastMessage("Please enter a valid wallet ID.");
    }
  };

  const handleStartClick = () => navigate('/app');

  return (
    <div>
      <div className="title">Sloppy Bird</div>

      {/* Toast Notification */}
  {showToast && (
  <div
    style={{
      position: 'fixed',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#2c3e50',
      color: '#ffdf00',
      padding: '10px 20px',
      borderRadius: '8px',
      border: '4px solid #ffcc00',
      fontFamily: '"Press Start 2P", cursive',
      fontSize: '1em',
      zIndex: 1500,  // High z-index to ensure it's above the modal
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.8)',
      textAlign: 'center',
      width: '80%',
      maxWidth: '300px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      animation: 'slideDownFade 0.5s ease-out, fadeOut 3s ease 2.5s forwards',
    }}
  >
    {toastMessage}
  </div>
)}

      <div className="card-container">
        <div className="card fadeIn">
          <span className="material-icons icon">check_circle</span>
          <h2>GET $SLOPPY</h2>
          <p>Embark on your journey with $SLOPPY. Become part of our evolving ecosystem</p>
          <button className="mint">Not live</button>
        </div>
        
        <div className="card fadeIn">
          <span className="material-icons icon">emoji_events</span>
          <h2>LEADERBOARDS</h2>
          <p>Compete against real players and climb the ranks to gain tokens in our weekly rewards.</p>
          <button className="view" onClick={() => setLeaderboardVisible(true)}>VIEW</button>
        </div>
        
        <Modal open={leaderboardVisible} onClose={() => setLeaderboardVisible(false)}>
  <Box
    sx={{
      padding: { xs: '10px', sm: '20px' }, // Responsive padding
    
      borderRadius: '8px',
   
  
      maxWidth: '600px',
      color: '#ffdf00',
      fontFamily: '"Press Start 2P", cursive',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: 'fadeInScale 0.3s ease-out',
    }}
  >
    <h2
      style={{
        fontSize: '1.5em',
        marginBottom: '20px',
        textTransform: 'uppercase',
        color: '#ffdf00',
        textShadow: '2px 2px 0px #3a474d',
        lineHeight: '1.2em',
      }}
    >
      Leaderboard
    </h2>
    <Leaderboard scores={leaderboardData} />
  </Box>
</Modal>
        <div className="card fadeIn">
          <span className="material-icons icon">sports_esports</span>
          <h2>PLAY</h2>
          <p>Play to earn Floppy Coins and set yourself on the top global leaderboards to earn rewards and prestige.</p>
          <button className="start" onClick={handleStartClick}>START</button>
        </div>

        <div className="card fadeIn referral-card">
          <span className="material-icons icon">share</span>
          <h2>REFERRAL LINK</h2>
          <p>Invite friends and earn rewards. Generate your unique referral link below:</p>
          <input
            type="text"
            placeholder="Enter wallet ID"
            value={walletId}
            onChange={(e) => setWalletId(e.target.value)}
            className="wallet-input"
          />
          <button className="generate-link" onClick={generateReferralLink}>Generate Link</button>
          {referralLink && (
            <div className="referral-info">
              <p>Your Referral Link:</p>
              <input
                type="text"
                value={referralLink}
                readOnly
                className="referral-link-input"
              />
              <button className="copy-link" onClick={copyReferralLink}>Copy Link</button>
              <p>Total Invites: {inviteCount}</p>
            </div>
          )}
        </div>
      </div>

      <div className="section about fadeIn">
        <h2 className="section-title">About Sloppy Bird Token</h2>
        <div className="content">
          <img style={{ width: "600px" }} src="https://i.imgur.com/He4VE8T.png" alt="Animated pixel art banner" className="about-banner"/>
          <ul className="about-list">
            <li><a href="https://t.me/sloppybirdSOL" target="_blank">Whitepapers</a></li>
            <li><a href="https://t.me/sloppybird" target="_blank">Telegram</a></li>
            <li><a href="https://twitter.com/sloppybirdSOL " target="_blank">Twitter</a></li>
          </ul>
        </div>
      </div>

      <div className="section how-it-works fadeIn">
        <h2 className="section-title">How It Works</h2>
        <div className="steps">
          <div className="step">
            <span className="material-icons icon">account_balance_wallet</span>
            <p>Create a digital wallet.</p>
          </div>
          <div className="step">
            <span className="material-icons icon">shopping_cart</span>
            <p>Buy $Sloppy Bird Tokens.</p>
          </div>
          <div className="step">
            <span className="material-icons icon">videogame_asset</span>
            <p>Ascend the ranks for rewards and tokens</p>
          </div>
        </div>
      </div>

      {/* Referral Modal */}
      <Modal
  open={showReferralModal}
  onClose={() => setShowReferralModal(false)}
  aria-labelledby="referral-modal-title"
  aria-describedby="referral-modal-description"
>
  <Box
    sx={{
      backgroundColor: '#2c3e50',
      padding: '20px',
      borderRadius: '10px',
      border: '4px solid #ffdf00',
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.8)',
      width: '90%',
      maxWidth: '500px',
      color: '#ffdf00',
      textAlign: 'center',
      fontFamily: '"Press Start 2P", cursive',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '@media (max-width: 600px)': {
        width: '90%',       // Full width on small screens
        padding: '15px',    // Reduce padding on mobile
        fontSize: '0.9em',  // Smaller font size for mobile
      },
    }}
  >
    <h2
      id="referral-modal-title"
      style={{
        fontSize: '1.2em',
        marginBottom: '15px',
        textTransform: 'uppercase',
        color: '#ffdf00',
        textShadow: '1px 1px 0px #3a474d',
        lineHeight: '1.2em',
      }}
    >
      Welcome! Enter Your Wallet ID
    </h2>
    <p
      id="referral-modal-description"
      style={{
        color: '#ffffff',
        fontSize: '0.85em',
        marginBottom: '15px',
        fontFamily: '"IBM Plex Mono", monospace',
        lineHeight: '1.2em',
      }}
    >
      To confirm your referral, please enter your wallet ID.
    </p>
    <input
      type="text"
      placeholder="Wallet ID"
      value={referredWalletId}
      onChange={(e) => setReferredWalletId(e.target.value)}
      style={{
        width: '100%',
        padding: '8px',
        marginBottom: '20px',
        border: '2px solid #ffcc00',
        borderRadius: '5px',
        fontSize: '1em',
        backgroundColor: '#34495e',
        color: '#ffffff',
        fontFamily: '"IBM Plex Mono", monospace',
        textAlign: 'center',
        boxSizing: 'border-box',
      }}
    />
    <button
      onClick={handleReferralSubmit}
      style={{
        width: '100%',
        padding: '10px',
        backgroundColor: '#ffcc00',
        border: 'none',
        borderRadius: '5px',
        fontWeight: 'bold',
        color: '#2c3e50',
        fontFamily: '"Press Start 2P", cursive',
        cursor: 'pointer',
        fontSize: '1em',
        textTransform: 'uppercase',
        transition: 'background-color 0.2s, transform 0.1s',
      }}
      onMouseOver={(e) => (e.target.style.backgroundColor = '#e6b800')}
      onMouseOut={(e) => (e.target.style.backgroundColor = '#ffcc00')}
      onMouseDown={(e) => (e.target.style.backgroundColor = '#d4a600')}
    >
      Submit
    </button>
  </Box>
</Modal>
    </div>
  );
};

export default SloppyBirdLandingPage;
